
import { defineComponent, onMounted } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "notification",
  data(){
    return {
      title: '',
      body: '',
      loading: false
    }
  },
  methods: {
    async notify(){
      try{
        this.loading = true
        await this.axios.post(`/notification/${this.$route.params.uid}`, {
          title: this.title,
          body: this.body
        })
        this.loading = false
        Swal.fire({
            text: 'The push notification has been sent to the user.',
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Okay",
            customClass: {
                confirmButton: "btn fw-bold btn-light-success",
            },
        })
        this.title = ''
        this.body = ''
      }catch(e){
        this.loading = false
        Swal.fire({
            text: e,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try Again",
            customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
            },
        })
      }
    }
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Overview", ["Send Push Notification"]);
    });
  },
});
